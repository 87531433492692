$offset: 10px;
.payments-table {
  border-spacing: 0 8px;
  td {
    height: 48px;
    border-color: white;

    &:first-child, {
      border-radius: var(--tui-radius-m) 0 0 var(--tui-radius-m);
    }

    &:last-child {
      border-radius: 0 var(--tui-radius-m) var(--tui-radius-m) 0;
    }
  }

  tbody{
    td:last-child{
      position: relative;
      &:before{
        position: absolute;
        top: $offset;
        right: $offset;
        content:'';
        background: var(--tui-primary);
        width: 2px;
        height: calc(100% - 2 * $offset);
      }
    }
  }

  th {
    @apply bg-neutral-100;
    border-color: transparent;
  }

}
