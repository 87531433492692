
.catalogs-table-wrapper{
  height: calc(100vh - 188px);
  overflow-y: auto;
}

.catalogs-table {
  border-spacing: 0 8px;
  td {
    @apply bg-neutral-100 border-neutral-100;
    height: 55px;

    &:first-child, {
      border-radius: var(--tui-radius-m) 0 0 var(--tui-radius-m);
    }

    &:last-child {
      border-radius: 0 var(--tui-radius-m) var(--tui-radius-m) 0;
    }
  }

  tfoot td {
    border-radius: var(--tui-radius-m)!important;
    padding: 0 12px;

    tui-table-pagination {
      margin: 0;
    }
  }

  tbody {
    td:last-child {
      position: relative;

      &:before {
        position: absolute;
        top: 10px;
        right: 10px;
        content: '';
        background: var(--tui-primary);
        width: 2px;
        height: 35px;
      }
    }
  }

  th {
    border-color: transparent;
  }

  &--md{
    tbody {
      td:last-child {
        &:before {
          height: 27px;
        }
      }
    }
  }

}

.journal-table + tui-table-pagination{
  @apply sticky bottom-0 py-4 w-full right-0 left-0 bg-white
}
