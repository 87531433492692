.plain-table {
  border-spacing: 0 8px;

  td {
    height: 20px;
    @apply bg-neutral-100 border-neutral-100;

    &:first-child {
      padding-left: 0!important;
      border-radius: var(--tui-radius-m) 0 0 var(--tui-radius-m);
    }

    &:last-child {
      border-radius: 0 var(--tui-radius-m) var(--tui-radius-m) 0;
    }
  }


  th {
    height: 20px;
    @apply bg-neutral-100;
    border-color: transparent;

    &:first-child, {
      padding-left: 0;
    }
  }

  tfoot {
    td {

      @apply bg-neutral-200 border-neutral-200;
      &:first-child {
        padding-left: 0;
        @apply rounded-l-md
      }

      &:last-child {
        @apply rounded-r-md
      }
    }
  }
}
