/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@taiga-ui/core/styles/taiga-ui-local';
@import "tables";

/* Reset and base styles  */

:root{
  --tui-primary: #3aa981;
  --tui-primary-hover: #5cc395
}
html, body {
  padding: 0;
  margin: 0;
  border: none;
  overscroll-behavior-y: none;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Links */

a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  margin: 0
}

/* Common */

aside, nav, footer, header, section, main {
  display: block;
}


label {
  cursor: pointer;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.2); /* Цвет фона с прозрачностью */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Тень */
  backdrop-filter: blur(20px); /* Эффект матового стекла */
}

.button-medium {
  max-width: 36px;
  max-height: 36px;

  span {
    font: var(--tui-font-text-xs) !important;
  }
}

label {
  font-size: 14px;
}

input, .t-textfield {
  font-size: medium !important;
}

.textarea-font-size-l textarea {
  font: var(--tui-font-text-m) !important;
}

app-autocomplete {
  display: block !important;
}

.app-document-header {
  background: color-mix(in srgb, var(--tui-base-04), transparent 50%);
  -webkit-backdrop-filter: blur(1.2rem);
  backdrop-filter: blur(1.2rem);
}

.app-control-records {
  background: color-mix(in srgb, var(--tui-base-04), transparent 10%);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

@mixin control-error-border {
  border: 2px solid red !important;
}

.control-error-area {
  &._disabled{
    @include control-error-border;
  }
  &:not(._disabled){
    [tuiWrapper][data-appearance=textfield]:after {
      @include control-error-border;
    }
  }
}

.detail-page {
  &__container {
    max-width: 1400px;
    min-height: 90vh;
    margin: 0 auto;
  }

  & app-input-date, app-autocomplete, tui-input, tui-input-number, tui-select, tui-textarea {
    &.ng-invalid {
      [tuiWrapper][data-appearance=textfield]:after {
        @include control-error-border
      }
    }
    &.field-has-changes{
      [tuiWrapper][data-appearance=textfield]:after {
       background: var(--tui-success-bg-hover)
      }
    }
  }
  & app-checkbox-labeled {
    &.field-has-changes{
      [tuiWrapper][data-appearance=outline]:after,[tuiWrapper][data-appearance=primary] {
        background: var(--tui-success-bg-hover);
        color: var(--tui-text-03);
      }
    }
  }
}

tui-textarea {
  &._disabled {
    label {
      opacity: 1 !important;
    }
  }
}

[tuiWrapper][data-appearance='control-group-button'] {
  background: transparent;
  color: #000;
}

.nomenclature tui-tree-item-content {
  display: block;
  flex-direction: column;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.tui-group_orientation_horizontal {
  [tuiWrapper][data-appearance=textfield] {
    box-shadow: 1px 0.125rem 0.1875rem rgba(0, 0, 0, 0.1);
  }
}


.declarations-table-wrapper {
  @apply h-[85vh] overflow-y-scroll hide-scrollbar
}

tui-island[tuiActiveZone]:not(ng-container){
  background: red;
}


tui-toggle{
  [tuiWrapper][data-appearance=secondary],[tuiWrapper][data-appearance=secondary]:hover:not(._no-hover) {
    background: var(--tui-base-05)
  }
}
